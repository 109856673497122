.bugify-forgot-modal {
  display: flex;
  padding: 10px;
  width: 345px !important;

  .ant-modal-content {
    width: 345px !important;
  }

  .header {
    font-size: 13pt;
    font-weight: bold;
    margin-bottom: 20px;
  }

  .label {
    margin-bottom: 10px;
    font-size: 11pt;
  }

  .captcha {
    margin-bottom: 15px;
  }

  .forgot-password {
    color: #ff9600;
    font-weight: 500;
    margin-bottom: 20px;
  }

  .verification-inputs-render {
    .verification-code-container {
      display: flex;
      width: 100%;

      .one-digit-input {
        flex: 1;
        border: 1px solid #e2e8f0;
        border-radius: 0;
        text-align: center;
        width: 50px !important;
        height: 50px;

        &:first-child {
          border-left: 1px solid #e2e8f0;
        }

        &:focus {
          outline: none !important;
          border: 1px solid #e2e8f0 !important;
          border-radius: 0 !important;
        }
      }
    }
  }
}
