@import "~antd/dist/antd.css";

.bugify-main-container {
  height: 100vh;
  width: 100vw;

  .ant-layout {
    height: 100%;

    .ant-layout-header {
      padding: 0px 16px;
    }

    .ant-layout-sider-has-trigger {
      width: 300px !important;
      min-width: 300px !important;
      max-width: 300px !important;

      .ant-layout-sider-trigger {
        display: none;
        width: 300px;
        min-width: 300px;
        max-width: 300px;
      }

      &.ant-layout-sider-collapsed {
        width: 80px !important;
        min-width: 80px !important;
        max-width: 80px !important;
      }
    }
  }
}

.ant-picker {
  width: 100%;
}

.ant-input-number {
  width: 100%;
}

.main-card-container {
  border-radius: 15px;
  max-height: 75vh;
  overflow: auto;
  overflow-x: hidden;
}

.ant-btn-primary {
  border-radius: 10px !important;
}

.ant-popover-inner-content {
  .ant-btn-primary {
    border-radius: 2px !important;
  }
}

.ant-modal {
  .ant-btn-primary {
    border-radius: 2px !important;
  }
}

.ant-input-affix-wrapper {
  border-radius: 6px;
}

.filters-section {
  display: flex;
  justify-content: flex-end;

  .ant-btn-primary {
    border-radius: 2px !important;
    margin-left: 20px;
  }
}
