.trigger {
  font-size: 22px;
  line-height: 64px;
  cursor: pointer;
  transition: color 0.3s;

  &:hover {
    color: #1890ff;
  }
}

.logo {
  height: 32px;
  margin: 16px;
  background: rgba(255, 255, 255, 0.3);
}

.ant-layout-header {
  border-bottom: 1px solid #ccc;
  overflow: hidden;
}

.site-layout .site-layout-background {
  background: #f0f2f5;
  overflow: hidden;
}

.bugify-navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;

  .bell-icon {
    font-size: 20px;
  }
}

.content-main-section {
  position: relative;
  padding: 0;
  margin: 16px;
}

.ant-popover-inner-content {
  padding: 5px 10px !important;
}

.user-inner-container {
  .profile-opt {
    width: 100px;
    padding: 10px 0;
    display: flex;
    align-items: center;
    border-bottom: 1px solid #ccc;
    cursor: pointer;
    column-gap: 15px;
    font-weight: 500;
  }

  .logout-opt {
    display: flex;
    align-items: center;
    width: 100px;
    padding: 10px 0;
    cursor: pointer;
    column-gap: 15px;
    font-weight: 500;
  }
}
