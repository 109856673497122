.bugify-account-created {
  width: 300px !important;

  .ant-space {
    display: flex;
    align-items: center;
    flex-direction: column;
    align-items: center;
  }

  .ant-typography {
    margin-bottom: 0;
  }
}
