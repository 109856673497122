.bugify-email-verification {
  display: flex;
  flex-direction: column;
  align-items: center;
  min-width: 320px !important;
  max-width: 320px !important;

  .verification-inputs-render {
    .verification-code-container {
      display: flex;
      width: 100%;

      .one-digit-input {
        flex: 1;
        border: 1px solid #e2e8f0;
        border-radius: 0;
        text-align: center;
        width: 50px !important;
        height: 50px;

        &:first-child {
          border-left: 1px solid #e2e8f0;
        }

        &:focus {
          outline: none !important;
          border: 1px solid #e2e8f0 !important;
          border-radius: 0 !important;
        }
      }
    }
  }
}
